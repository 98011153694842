class ValidationSchema {
  schema = {};
  errors = [];

  /**
   * Creates a new ValidationSchema instance.
   * @param {Object} schema The schema object {type?: string, required?: boolean, values?: any[], test?: (Object) => boolean}.
   * @param {function?} test The test function to validate the data.
   */
  constructor(schema, test) {
    this.schema = schema;
    this.test = test;
  }

  /**
   * Validates the data against the schema.
   * @param {Object} data The data to be validated.
   * @returns {Boolean} true if the data is valid according to the schema, otherwise false.
   */
  validate(data, logErrors = false) {
    this.errors = [];
    for (const key in this.schema) {
      const { type, required, values, test, errorMessage, multipleValidation } = this.schema[key];
      const fieldValue = data[key];

      if (multipleValidation) {
        const result = test(data);
        if (result.errorMessage) {
          this.errors.push({ key, message: result.errorMessage });
        }
      }

      if (test && !test(data)) {
        this.errors.push({ key, message: errorMessage });
        continue;
      }

      if (required) {
        if (fieldValue === undefined || fieldValue.length === 0) {
          this.errors.push({
            key,
            message: `${key} is required, ${fieldValue}`,
          });
          continue;
        }

        if (type && typeof fieldValue !== type) {
          this.errors.push({
            key,
            message: `${key} must be of type ${type}, but ${fieldValue} have type ${typeof fieldValue}`,
          });
          continue;
        }
      }

      if (
        values &&
        values?.length &&
        fieldValue !== undefined &&
        !values.includes(fieldValue)
      ) {
        this.errors.push({
          key,
          message: `${key} must be one of ${values.join(", ")}`,
        });
      }
    }

    if (this.test && !this.test(data)) {
      this.errors.push({ key: "test", message: "Test failed" });
    }

    return {
      errors: this.errors,
      isValidated: this.errors.length === 0,
    };
  }
}

module.exports = {
  ValidationSchema,
};
